import { render, staticRenderFns } from "./TabBar.global.vue?vue&type=template&id=6720e2ee&scoped=true&lang=pug&"
import script from "./TabBar.global.vue?vue&type=script&lang=ts&"
export * from "./TabBar.global.vue?vue&type=script&lang=ts&"
import style0 from "./TabBar.global.vue?vue&type=style&index=0&id=6720e2ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6720e2ee",
  null
  
)

export default component.exports