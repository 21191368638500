
















import { IntegerProp, StringProp } from '@/util/prop-decorators';
import { range } from 'lodash';
import { Component, Model, Vue } from 'vue-property-decorator';
import PageButton from './PageButton.vue';

@Component({ components: { PageButton } })
export default class Pagination extends Vue {
  @Model('input', { type: Number, required: true })
  public value!: number;
  @IntegerProp(true, 0)
  public totalPages!: number;
  @IntegerProp(5, 0)
  public displayPages!: number;

  @StringProp()
  private readonly dataElementId?: string;

  private currentPage = 0;

  private mounted(): void {
    this.currentPage = this.value;
  }

  public get list(): number[] {
    const { displayPages, totalPages, value } = this;

    if (displayPages < 1) {
      return [];
    }

    const neighbors = Math.ceil((displayPages - 1) / 2);

    let from = value - neighbors;
    let to = value + neighbors;

    if (from < 1) {
      to -= from - 1;
    }
    if (to > totalPages) {
      from -= to - totalPages;
    }

    return range(Math.max(1, from), Math.min(to, totalPages) + 1);
  }

  public get prevPage(): number {
    return Math.max(this.value - 1, 1);
  }

  public get nextPage(): number {
    return Math.min(this.value + 1, this.totalPages);
  }

  public input(value: number): void {
    this.currentPage = value;
    this.$emit('input', value);
  }
}
