











import { StringProp, ArrayProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormMessage extends Vue {
  @StringProp()
  private readonly headline?: string;

  @StringProp()
  private readonly message?: string;

  @ArrayProp(() => [])
  private readonly messages!: string[];

  @BooleanProp()
  private readonly error!: boolean;

  @BooleanProp()
  private readonly warning!: boolean;

  @BooleanProp()
  private readonly success!: boolean;

  private get normalizedMessages(): string[] {
    return this.message === undefined ? this.messages : [this.message, ...this.messages];
  }
}
