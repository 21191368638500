





import { toLength } from '@/util/css-length';
import { BooleanProp, LengthProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Panel extends Vue {
  @LengthProp()
  private readonly padding?: string | number;

  @BooleanProp()
  private readonly stretch!: boolean;

  @LengthProp()
  private readonly width?: string | number;

  @LengthProp()
  private readonly minWidth?: string | number;

  @LengthProp()
  private readonly maxWidth?: string | number;

  @LengthProp()
  private readonly height?: string | number;

  @LengthProp()
  private readonly minHeight?: string | number;

  @LengthProp()
  private readonly maxHeight?: string | number;

  private get style(): Record<string, string | undefined> {
    return {
      padding: toLength(this.padding),
      width: toLength(this.width),
      'min-width': toLength(this.minWidth),
      'max-width': toLength(this.maxWidth),
      height: toLength(this.height),
      'min-height': toLength(this.minHeight),
      'max-height': toLength(this.maxHeight),
    };
  }
}
