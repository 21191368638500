














































import { Order } from '@/types/iot-portal';
import { toLength } from '@/util/css-length';
import { isString } from '@/util/lang';
import { ArrayProp, BooleanProp, FunctionProp, IntegerProp, StringProp } from '@/util/prop-decorators';
import { get, isEqual, startCase } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Accessor, Column, IsRowExpanded, NormalizedColumn, SortKey } from './model';

function createAccessor(accessor: string | Accessor): Accessor {
  return isString(accessor) ? (row) => get(row, accessor) : accessor;
}

@Component({
  data() {
    return { selectedRowsMap: new Map<number, unknown>() };
  },
})
export default class Table extends Vue {
  @ArrayProp(() => [])
  private readonly rows!: unknown[];

  @ArrayProp(() => [])
  private selectedRows!: unknown[];

  @ArrayProp(() => [])
  private readonly columns!: Column[];

  @ArrayProp(() => [])
  private readonly hiddenColumns!: string[];

  @ArrayProp()
  private readonly enabledColumns?: string[];

  @ArrayProp(() => [])
  private readonly disabledColumns!: string[];

  @ArrayProp(() => [])
  private readonly sortKeys!: SortKey[];

  @BooleanProp()
  private readonly headless!: boolean;

  @BooleanProp()
  private readonly fixed!: boolean;

  @BooleanProp()
  private readonly selectable!: boolean;

  @IntegerProp(Number.MAX_SAFE_INTEGER, 1)
  private readonly renderSlices!: number;

  @FunctionProp(() => false)
  private readonly isRowExpanded!: IsRowExpanded;

  @StringProp()
  private readonly dataElementId?: string;

  private selectedRowsMap!: Map<number, unknown>;

  private slice = Number.MAX_SAFE_INTEGER;

  private get normalizedColumns(): NormalizedColumn[] {
    return this.columns.map(
      ({ name, field, accessor, label, align, verticalAlign, sortable, hideable, width, order }, index) => ({
        name,
        accessor: createAccessor(accessor ?? field ?? name),
        label: label ?? startCase(name),
        sortable: sortable === true ? name : sortable || undefined,
        align: align ?? 'left',
        verticalAlign: verticalAlign ?? 'middle',
        hideable: hideable || false,
        index,
        width: toLength(width),
        order: order ?? index,
      }),
    );
  }

  private get filteredColumns(): NormalizedColumn[] {
    const enabledColumnSet = new Set(this.enabledColumns ?? this.columns.map(({ name }) => name));
    const disabledColumnSet = new Set(this.disabledColumns);

    return this.normalizedColumns
      .filter(({ name }) => enabledColumnSet.has(name))
      .filter(({ name }) => !disabledColumnSet.has(name));
  }

  private get shownColumns(): NormalizedColumn[] {
    return this.filteredColumns
      .filter(({ name, hideable }) => !hideable || !this.hiddenColumns.includes(name))
      .sort((a, b) => a.order - b.order);
  }

  private get slicedRows(): unknown[] {
    return this.rows.length > this.slice ? this.rows.slice(0, this.slice) : this.rows;
  }

  private created(): void {
    this.slice = this.renderSlices;
  }

  private async mounted(): Promise<void> {
    while (this.rows.length > this.slice) {
      await new Promise((resolve) => requestAnimationFrame(resolve));
      this.slice += this.renderSlices;
    }

    this.slice = Number.MAX_SAFE_INTEGER;
  }

  private getSortDirection({ sortable }: NormalizedColumn): Order {
    const { direction } = this.sortKeys.find(({ key }) => key === sortable) || { direction: Order.ASC };

    return direction;
  }

  private toggleSortKey(column: NormalizedColumn): void {
    if (!column.sortable) {
      return;
    }

    const sortKeys = this.sortKeys.filter(({ key }) => key !== column.sortable);

    sortKeys.unshift({
      key: column.sortable,
      direction: this.getSortDirection(column) === Order.ASC ? Order.DESC : Order.ASC,
    });

    this.$emit('update:sortKeys', sortKeys);
  }

  private toggleSelectAll(): void {
    if (this.selectedRows.length === this.rows.length) {
      this.$emit('update:selectedRows', []);
    } else {
      this.$emit('update:selectedRows', this.rows);
    }
  }

  private isRowSelected(index: number): boolean {
    return this.selectedRowsMap.has(index);
  }

  private toggleSelectRow(index: number): void {
    if (!this.isRowSelected(index)) {
      this.$emit('update:selectedRows', this.selectedRows.concat(this.rows[index]));
    } else {
      this.$emit(
        'update:selectedRows',
        this.selectedRows.filter((row) => row !== this.selectedRowsMap.get(index)),
      );
    }
  }

  @Watch('rows', { immediate: true })
  private onRowsChange(): void {
    this.$emit('update:selectedRows', []);
  }

  @Watch('selectedRows', { immediate: true })
  private onSelectedRowsChange(): void {
    // create selectedRowsMap
    this.selectedRowsMap = new Map();
    for (const selectedRow of this.selectedRows) {
      const index = this.rows.findIndex((row) => isEqual(row, selectedRow));
      this.selectedRowsMap.set(index, selectedRow);
    }
  }
}
