import { render, staticRenderFns } from "./TabButton.global.vue?vue&type=template&id=496c0ecc&scoped=true&lang=html&"
import script from "./TabButton.global.vue?vue&type=script&lang=ts&"
export * from "./TabButton.global.vue?vue&type=script&lang=ts&"
import style0 from "./TabButton.global.vue?vue&type=style&index=0&id=496c0ecc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496c0ecc",
  null
  
)

export default component.exports