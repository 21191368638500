





























import { ArrayProp, StringProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

// TODO dont show description tooltip, if no overflow/text-overflow
// TODO dont show error tooltip, if errorSlots + errorMessages === 1 and no
// overflow/text-overflow
// detect horizontal overflow/text-overflow: e.offsetWidth < e.scrollWidth
// detect vertial overflow: e.offsetHeight < e.scrollHeight

@Component({})
export default class InputField extends Vue {
  @StringProp()
  private readonly label?: string;

  @StringProp()
  private readonly description?: string;

  @BooleanProp()
  private readonly error!: boolean;

  @ArrayProp(() => [])
  private readonly errorMessages!: string[];

  private get errorSlotNames(): string[] {
    return Object.keys(this.$scopedSlots).filter((name) => name.startsWith('error'));
  }

  private get errorContent(): boolean {
    return this.errorMessages.length > 0 || this.errorSlotNames.length > 0;
  }
}
