





import Busyable, { Busy } from '@/features/ui/mixins/busyable';
import { BooleanProp, FunctionProp, ObjectProp } from '@/util/prop-decorators';
import { isString } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import FormContext from './form-context';
import type { FormAction, FormDataRecord } from './model';

@Component({
  data() {
    return { actionError: undefined };
  },
})
export default class Form extends Mixins(Busyable, FormContext) {
  @ObjectProp()
  private readonly initialData?: FormDataRecord;

  @FunctionProp()
  private readonly action?: FormAction;

  @BooleanProp()
  private readonly noReset!: boolean;

  private actionError?: unknown;

  private get errorMessages(): string[] {
    const messages: string[] = [];

    if (this.actionError !== undefined) {
      messages.push(
        isString(this.actionError)
          ? this.actionError
          : this.actionError instanceof Error && this.actionError.message.length > 0
          ? this.actionError.message
          : 'Es ist ein Fehler aufgetreten',
      );
    }

    return messages;
  }

  protected getInitialData(): FormDataRecord | undefined {
    return this.initialData;
  }

  @Busy()
  private async submit(param?: unknown): Promise<void> {
    const data = this.collect();

    this.actionError = undefined;
    let result = undefined;

    if (this.action) {
      try {
        result = await this.action(data, param);
      } catch (e) {
        // eslint-disable-next-line no-console -- dont swallow errors
        console.error(e);

        this.actionError = e;
        this.$emit('error', this.errorMessages);
        return;
      }
    }

    this.$emit('submitted', { data, result });

    if (!this.noReset) {
      this.reset();
    }
  }
}
