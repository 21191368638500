








import { BooleanProp, OptionalProp } from '@/util/prop-decorators';
import Vue from 'vue';
import { Component, Model } from 'vue-property-decorator';
import { Input } from './model';

@Component
export default class InputSwitch<T = unknown> extends Vue implements Input {
  @Model('update')
  private readonly value!: T;

  @BooleanProp()
  private readonly error!: boolean;

  @OptionalProp(false)
  private readonly leftValue!: T;

  @OptionalProp(true)
  private readonly rightValue!: T;

  @OptionalProp()
  private readonly leftColor?: string;

  @OptionalProp()
  private readonly rightColor?: string;

  @BooleanProp()
  private readonly alignRight!: boolean;

  public pristine = true;

  private get model(): boolean {
    return this.value === this.rightValue;
  }

  private set model(value: boolean) {
    this.$emit('update', this.normalizeValue(value));
  }

  public get normalizedValue(): T {
    return this.normalizeValue(this.model);
  }

  public get empty(): boolean {
    return false;
  }

  private get style(): Record<string, string | undefined> {
    return { '--left-color': this.leftColor, '--right-color': this.rightColor };
  }

  private normalizeValue(value: boolean): T {
    return value ? this.rightValue : this.leftValue;
  }
}
