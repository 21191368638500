




























import Id from '@/features/ui/mixins/id';
import { random, seed } from '@/util/prng-park-miller-carta';
import { BooleanProp, IntegerProp } from '@/util/prop-decorators';
import { Component, Mixins, Watch } from 'vue-property-decorator';

@Component({
  data() {
    return { contentHeight: undefined };
  },
})
export default class TableSkeleton extends Mixins(Id) {
  @BooleanProp()
  private readonly ready!: boolean;

  @IntegerProp(7, 1)
  private readonly rowCount!: number;

  @IntegerProp(1, 1)
  private readonly columnCount!: number;

  @IntegerProp(1, 1)
  private readonly lineCount!: number;

  @IntegerProp(1, 0)
  private readonly headerLineCount!: number;

  private contentHeight?: number;

  // non-reactive on purpose
  private seed!: number;

  private get skeletonStyle(): Record<string, string> {
    return {
      '--header-cell-font-size': 'var(--fs-50)',
      '--header-cell-line-height': 'calc(var(--lh-100) * var(--header-cell-font-size))',
      '--header-cell-offset': 'calc(10px + (var(--header-cell-line-height) - var(--header-cell-font-size)) / 2)',
      '--header-cell-height':
        this.headerLineCount > 0 ? `calc(${this.headerLineCount} * var(--header-cell-line-height) + 21px)` : '0px',
      '--cell-font-size': 'var(--fs-100)',
      '--cell-line-height': 'calc(var(--lh-100) * var(--cell-font-size))',
      '--cell-offset': 'calc(10px + (var(--cell-line-height) - var(--cell-font-size)) / 2)',
      '--cell-height': `calc(${this.lineCount} * var(--cell-line-height) + 21px)`,
      '--column-width': `${(1 / this.columnCount) * 100}%`,
      height:
        this.contentHeight === undefined
          ? `calc(var(--header-cell-height) + ${this.rowCount} * var(--cell-height))`
          : `${this.contentHeight}px`,
    };
  }

  private get maskRectStyles(): Record<string, string>[] {
    const rand = random((this.seed ??= seed()));
    const styles: Record<string, string>[] = [];

    for (let j = 0; j < this.headerLineCount; j++) {
      const y = `calc(var(--header-cell-offset) + ${j} * var(--header-cell-line-height))`;
      for (let k = 0; k < this.columnCount; k++) {
        styles.push({
          x: `calc(${k} * var(--column-width) + 10px)`,
          y,
          width: `calc((var(--column-width) - 20px) * ${rand.next().value * 0.8 + 0.2})`,
          height: 'var(--header-cell-font-size)',
        });
      }
    }

    for (let i = 0; i < this.rowCount; i++) {
      for (let j = 0; j < this.lineCount; j++) {
        const y = `
          calc(
            var(--header-cell-height)
            + ${i} * var(--cell-height)
            + var(--cell-offset)
            + ${j} * var(--cell-line-height)
          )
        `;
        for (let k = 0; k < this.columnCount; k++) {
          styles.push({
            x: `calc(${k} * var(--column-width) + 10px)`,
            y,
            width: `calc((var(--column-width) - 20px) * ${rand.next().value * 0.8 + 0.2})`,
            height: 'var(--cell-font-size)',
          });
        }
      }
    }

    return styles;
  }

  @Watch('ready')
  private measureContentHeight(): void {
    if (this.ready) {
      return;
    }

    this.contentHeight = this.$el?.clientHeight;
  }
}
