






import { RequiredProp } from '@/util/prop-decorators';
import { Component, Vue, Inject } from 'vue-property-decorator';
import TabBar from './TabBar.global.vue';
import { TAB_BAR } from './model';

@Component
export default class TabButton extends Vue {
  @RequiredProp()
  public readonly value: unknown;

  @Inject(TAB_BAR)
  private readonly tabBar!: TabBar;

  private get active(): boolean {
    return this.tabBar.isSelected(this);
  }

  private onClick(): void {
    this.tabBar.select(this);
  }

  private destroyed(): void {
    this.tabBar.remove(this);
  }
}
