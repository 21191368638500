import { throttle } from 'lodash';
import { isFunction } from '@/util/lang';

export default function Throttle(wait = 0, leading = true, trailing = true): MethodDecorator {
  // eslint-disable-next-line @typescript-eslint/ban-types -- lookup map for instances
  const instanceMap = new WeakMap<object, (...args: unknown[]) => unknown>();

  return (target, propertyKey, descriptor: PropertyDescriptor) => {
    const method: unknown = descriptor.value;

    if (!isFunction(method)) {
      return;
    }

    descriptor.value = function (...args: unknown[]) {
      const throttled = instanceMap.get(this) || throttle(method, wait, { leading, trailing });

      if (!instanceMap.has(this)) {
        instanceMap.set(this, throttled);
      }

      throttled.apply(this, args);
    };
  };
}
