















import NextTick from '@/util/next-tick-decorator';
import { EnumProp, NumberProp, FunctionProp } from '@/util/prop-decorators';
import Throttle from '@/util/throttle-decorator';
import { clamp, isEqual, mapValues } from 'lodash';
import { Component, Model, Vue, Watch } from 'vue-property-decorator';
import { ResizeObserver } from 'vue-resize';
import TabButton from './TabButton.global.vue';
import { TAB_BAR } from './model';

function rectOf(element: Element): { left: number; width: number } {
  const { left, right } = element.getBoundingClientRect();

  return { left, width: right - left };
}

@Component({
  components: { ResizeObserver },
  provide() {
    return { [TAB_BAR]: this };
  },
})
export default class TabBar extends Vue {
  @Model('update')
  private readonly value: unknown;

  @EnumProp('bottom', 'top')
  private readonly align!: string;

  @NumberProp(0.66, 0)
  private readonly scrollRatio!: number;

  @FunctionProp(isEqual)
  private readonly matchValue!: (selectedValue: unknown, buttonValue: unknown) => boolean;

  private selected: TabButton | null = null;

  private indicatorClasses = {
    on: false,
    left: false,
    right: false,
  };

  private indicatorPosition = {
    left: -200,
    right: 0,
  };

  private fits = true;

  private scrollLeft = 0;

  public $refs!: { scrollWrapper: Element; scroll: Element };

  private get indicatorStyle(): Record<string, string> {
    return mapValues(this.indicatorPosition, (value) => `${value}px`);
  }

  private get scrollStyle(): Record<string, string> {
    return { left: `${this.scrollLeft}px` };
  }

  private get barClasses(): Record<string, boolean> {
    const { align, fits } = this;

    return { [align]: true, fits };
  }

  @Watch('selected')
  @NextTick(true)
  private updateIndicator(): void {
    if (!this.selected) {
      this.indicatorClasses.on = false;
      this.indicatorClasses.left = false;
      this.indicatorClasses.right = false;

      return;
    }

    const selected = this.selected.$el;
    const scroll = this.$refs.scroll;

    const previousLeft = this.indicatorPosition.left;

    const selectedRect = selected.getBoundingClientRect();
    const barRect = scroll.getBoundingClientRect();

    this.indicatorPosition.left = selectedRect.left - barRect.left;
    this.indicatorPosition.right = barRect.right - selectedRect.right;

    if (this.indicatorClasses.on === false) {
      this.indicatorClasses.on = true;

      return;
    }

    this.indicatorClasses.left = previousLeft > this.indicatorPosition.left;
    this.indicatorClasses.right = previousLeft < this.indicatorPosition.left;
  }

  @Throttle(500, false)
  private resized(): void {
    this.updateScroll();
  }

  @Watch('selected')
  @NextTick(true)
  private updateScroll(): void {
    if (!this.selected) {
      return;
    }

    const scrollWrapperRect = rectOf(this.$refs.scrollWrapper);
    const scrollRect = rectOf(this.$refs.scroll);
    const selectedRect = rectOf(this.selected.$el);

    const left = scrollRect.left - selectedRect.left + scrollWrapperRect.width / 2 - selectedRect.width / 2;

    this.setScrollLeft(left);
  }

  private scroll(amount: number): void {
    const diff = rectOf(this.$refs.scrollWrapper).width * amount;

    this.setScrollLeft(this.scrollLeft + diff);
  }

  private setScrollLeft(scrollLeft: number): void {
    const maxScrollLeft = rectOf(this.$refs.scrollWrapper).width - rectOf(this.$refs.scroll).width;

    if (maxScrollLeft >= 0) {
      this.fits = true;
      this.scrollLeft = 0;

      return;
    }

    this.fits = false;
    this.scrollLeft = clamp(scrollLeft, maxScrollLeft, 0);
  }

  @Watch('value')
  private resetSelected(value: unknown): void {
    if (this.selected && !this.matchValue(value, this.selected.value)) {
      this.selected = null;
    }
  }

  public isSelected(button: TabButton): boolean {
    if (this.selected) {
      return this.selected === button;
    }

    if (this.matchValue(this.value, button.value)) {
      this.selected = button;

      return true;
    }

    return false;
  }
  public getSelected(): TabButton | null {
    return this.selected;
  }
  public select(button: TabButton): void {
    this.selected = button;
    this.$emit('update', button.value);
  }

  public remove(button: TabButton): void {
    if (this.selected === button) {
      this.selected = null;
    }
  }
}
