// simple PRNG
// see http://www.firstpr.com.au/dsp/rand31/
// see https://gist.github.com/blixt/f17b47c62508be59987b

export function seed(): number {
  return Math.floor(Math.random() * 2147483647);
}

export function* random(s: number = seed()): Generator<number> {
  while (true) {
    s = (s * 16807) % 2147483647;

    yield (s - 1) / 2147483646;
  }
}
