




import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Html extends Vue {
  @StringProp()
  private readonly value!: string;
}
