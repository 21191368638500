
















import { StringProp, OptionalProp, ObjectProp } from '@/util/prop-decorators';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { Input } from '../inputs/model';
import FormComponent from './form-component';

@Component({
  data() {
    return { inputRef: undefined };
  },
})
export default class FormField extends Mixins(FormComponent) {
  @StringProp(true)
  private readonly input!: string;

  @ObjectProp()
  private readonly inputProps?: Record<string, unknown>;

  @StringProp()
  private readonly label?: string;

  @StringProp()
  private readonly description?: string;

  @OptionalProp(null)
  private readonly emptyValue?: unknown;

  public readonly $refs!: { input: Vue & Input };

  private inputRef?: Vue & Input;

  private get fieldSlotNames(): string[] {
    return Object.keys(this.$scopedSlots).filter((name) => !name.startsWith('input'));
  }

  private get inputSlotNameMap(): Record<string, string> {
    return Object.fromEntries(
      Object.keys(this.$scopedSlots)
        .filter((name) => name.startsWith('input'))
        .map((name) => [name, name[5].toLowerCase() + name.slice(6)]),
    );
  }

  private mounted(): void {
    this.inputRef = this.$refs.input;
  }

  private updated(): void {
    this.inputRef = this.$refs.input;
  }

  protected resolveValue(): unknown {
    return this.inputRef?.empty === false ? this.inputRef.normalizedValue : this.emptyValue;
  }
}
