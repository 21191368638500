






import { IntegerProp } from '@/util/prop-decorators';
import { Component, Model, Vue } from 'vue-property-decorator';

@Component
export default class PageButton extends Vue {
  @Model(undefined, { type: Number, required: true })
  public value!: number;
  @IntegerProp(true, 1)
  public page!: number;
  @IntegerProp(true, 1)
  public totalPages!: number;

  public get width(): string {
    return `${Math.floor(Math.log10(this.totalPages) + 1)}ch`;
  }
}
