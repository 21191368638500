


















import { StringProp, OptionalProp, BooleanProp } from '@/util/prop-decorators';
import { isArray } from 'lodash';
import { Component, Vue, Model } from 'vue-property-decorator';
import { Input } from './model';

@Component
export default class InputCheckbox<T = unknown> extends Vue implements Input {
  @Model('update')
  private readonly value!: T | unknown[];

  @BooleanProp()
  private readonly indeterminate!: boolean;

  @BooleanProp()
  private readonly hidden!: boolean;

  @BooleanProp()
  private readonly implicit!: boolean;

  @BooleanProp()
  private readonly disabled!: boolean;

  @OptionalProp()
  private readonly checkedValue?: unknown;

  @OptionalProp(false)
  private readonly falseValue!: T;

  @OptionalProp(true)
  private readonly trueValue!: T;

  @StringProp('')
  private readonly optionLabel!: string;

  @BooleanProp()
  private readonly fullOpacity!: boolean;

  public pristine = true;

  private get model(): boolean | unknown[] {
    return isArray(this.value) ? this.value : this.value === this.trueValue;
  }

  private set model(value: boolean | unknown[]) {
    this.$emit('update', isArray(value) ? value : this.normalizeValue(value));
  }

  public get normalizedValue(): T | unknown[] {
    return isArray(this.model) ? this.model : this.normalizeValue(this.model);
  }

  public get empty(): boolean {
    return false;
  }

  private normalizeValue(value: boolean): T {
    return value ? this.trueValue : this.falseValue;
  }
}
